// colors defined here : https://vooban.atlassian.net/browse/TOS-8717
$offset: #e2e2e2;
$warning: #fdf1d8;
$error: #fde1e1;

.rdg-row-link {
  .rdg-row-actions {
    span {
      &.-sticked {
        padding-left: 3rem;
        padding-right: 8px;

        .list__action {
          &.button {
            height: 24px;
            width: 24px;
            color: cc(grey);
          }

          &.button:hover {
            color: cc(primary);
          }
        }
      }
    }
  }

  &.-warning {
    background-color: $warning;
    @include setRowHighlighting($warning);
    &.-odd {
      @include setRowHighlighting(lighten($warning, 10%));
    }
  }
  &.-offset {
    background-color: $offset;
    @include setRowHighlighting($offset);
    &.-odd {
      @include setRowHighlighting(lighten($offset, 10%));
    }
  }
  &.-error {
    background-color: $error;
    @include setRowHighlighting($error);
    &.-odd {
      @include setRowHighlighting(lighten($error, 10%));
    }
  }
}

.rdg-cell-formatter {
  font-weight: 500;

  .-ref-link {
    color: cc(primary, default);
    &:hover {
      text-decoration: underline;
    }
  }
  &.-ligthen-text {
    color: lighten(cc(body, text), 50%);
  }
}

.rdg-hide-header {
  .rdg-header-row {
    position: absolute;
    visibility: hidden;
  }
}
