.tooltip {
  &__icon {
    position: absolute;
    margin-top: -1px;

    &.-margin-left {
      margin-left: 4px;
    }

    &.-data-grid-header {
      margin-top: 7px;
    }
  }

  &__section {
    & .form {
      width: 700px;
    }
  }

  &__section-title {
    text-transform: uppercase;
    font-size: convert-rem(11);
    font-weight: bolder;
  }

  &__section-data {
    max-height: convert-rem(200);
    pointer-events: all;
  }
}
