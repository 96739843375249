@import 'variables';

.icon {
  display: inline-block;
  line-height: 0;
  text-decoration: inherit;

  &.grid {
    font-size: 14px;
  }

  &.-disk {
    position: relative;
    padding: 0.4em;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2.2em;
      height: 2.2em;
      border-radius: 100%;
      transform: translate3d(-50%, -50%, 0);
      transition: background-color 125ms linear;
    }

    &:hover::before {
      background-color: white;

      .-with-background & {
        background-color: cc(body, background);
      }
    }
  }

  &.-default-cursor {
    cursor: auto;
  }

  &.-textless {
    margin: 0;
  }

  &.-vertical-top {
    vertical-align: top;
    margin-left: 4px;
  }

  &__svg {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;

    .icon.-rotate-clockwise & {
      transform: rotate(90deg);
    }

    .icon.-rotate-counterclockwise & {
      transform: rotate(-90deg);
    }
  }

  &__traffic {
    &.-drop {
      .st0 {
        fill: $dropColor;
      }

      .stroke {
        stroke: $dropColor !important;
      }

      .fill {
        fill: $dropColor !important;
      }
    }

    &.-pick {
      .st0 {
        fill: $pickColor;
      }

      .stroke {
        stroke: $pickColor !important;
      }

      .fill {
        fill: $pickColor !important;
      }
    }
  }
}
