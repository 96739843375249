.monthly-calendar {
  margin-top: convert-rem(20);
  border: 1px solid cc(offset, lighter);

  &__row {
    position: relative;
    display: flex;

    & + & {
      border-top: 1px solid cc(offset, lighter);
    }
  }

  &__weekday {
    @include type(date, day);
    position: absolute;
    top: convert-rem(4);
    left: convert-rem(4);
    width: convert-rem(24);
    text-align: center;
  }

  &__day {
    position: relative;
    width: calc(100% / 7);
    color: cc(grey, darker);
    background-color: white;
    backface-visibility: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 85%;
    }

    &::after {
      @include type(link);
      content: attr(data-date);
      position: absolute;
      top: convert-rem(4);
      left: convert-rem(4);
      display: flex;
      align-items: center;
      justify-content: center;
      width: convert-rem(28);
      height: convert-rem(28);
      padding-top: 1px;
      padding-right: 1px;
      background-color: cc(primary, 0);
      border-radius: 100%;
      backface-visibility: hidden;
      line-height: 0;
      font-variant-numeric: normal;
    }

    & + & {
      border-left: 1px solid cc(offset, lighter);
    }

    &.-active::after {
      color: white;
      background-color: cc(primary);
    }

    .-first-week &::after {
      transform: translateY(50%);
    }
  }

  &__projects {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: convert-rem(36);
    backface-visibility: hidden;

    .-first-week & {
      padding-top: convert-rem(46);
    }
  }

  &__lane {
    position: relative;
    display: flex;
    flex: 1 0 0;
    max-height: convert-rem(24);

    & + & {
      margin-top: convert-rem(4);
    }
  }

  &__timeline {
    display: block;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    padding-right: convert-rem(20);
    background-color: cc(offset, dark, 0.25);
    backface-visibility: hidden;

    &.-gap {
      visibility: hidden;
      pointer-events: none;
    }

    &.-vessel-unloading {
      background-color: cc(project-type, vessel-unloading, 0.25);
      border-color: cc(project-type, vessel-unloading);
    }

    &.-vessel-loading {
      background-color: cc(project-type, vessel-loading, 0.25);
      border-color: cc(project-type, vessel-loading);
    }

    &.-other {
      background-color: cc(project-type, other, 0.25);
      border-color: cc(project-type, other);
    }

    &.-more {
      left: 0;
      width: 100%;
      background-color: transparent;
      border: none;
    }

    .-default & {
      pointer-events: auto;
    }
  }

  &__label {
    overflow: hidden;
    width: 100%;
    padding-top: 1px;
    text-align: right;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    &.-more {
      font-weight: 600;
      text-transform: none;
    }
  }
}
