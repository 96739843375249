.project {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  backface-visibility: hidden;
  pointer-events: auto;

  &__month {
    width: 100%;
    padding-top: 1px;
    pointer-events: auto;
  }
}
