.tile {
  &__traffic {
    border-radius: convert-rem(5);
    background-color: #f8f8f8;
    padding: convert-rem(20);
    flex-grow: 5;
    align-content: flex-start;
    min-height: convert-rem(150);

    .appointment-row {
      border-radius: convert-rem(5);
      background-color: transparent;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: #e0e0e0;
      }
    }

    .appointment-row-cell {
      display: flex;
      align-items: center;
      height: convert-rem(40);

      &.-header {
        height: convert-rem(30);
        align-items: start;
      }
    }

    .appointment-row-cell svg {
      display: block;
    }
  }

  &__labor-availability {
    border: 1px solid cc(offset, light);
    border-radius: convert-rem(5);

    .label {
      color: cc(offset, medium);
      font-size: convert-rem(13);
      font-weight: 600;
    }

    .separator {
      margin: convert-rem(10) 0;
      border-top: 1px solid cc(offset, light);
    }
  }
}
