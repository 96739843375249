.arrow {
  border: solid cc(primary, darkest);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.gradiant {
  background-color: cc(primary, darkest);
  content: ' ';
  position: absolute;
  display: block;
  width: 2rem;
  height: 2rem;
  opacity: 0.1;
}

.radius-left {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.radius-right {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.react-datepicker {
  &__month-select,
  &__year-select {
    font-weight: 600;
    font-size: 1rem;
  }
  &__month-select {
    text-align: right;
  }
  &__year-select {
    text-align: left;
  }
  &__current-month {
    height: 3rem;
  }
  &__header {
    background-color: white;
    &__dropdown {
      left: 2em !important;
      right: 2em !important;
      top: 0.75em !important;
    }
  }
  &__month-dropdown-container {
    line-height: 3em;
  }
  &__month-select {
    background: white;
    width: 6.5em;
    color: cc(primary, darkest);
  }
  &__year-select {
    background: white;
    width: 3.75em;
    color: cc(primary, darkest);
  }
  &__day-name {
    color: cc(primary, darkest);
  }
  &__week {
    margin: 0.5em 0;
  }
  &__day {
    margin: 0;
    width: 2rem;
    height: 2rem;
    z-index: 0;
    font-size: convert-rem(14);
    font-weight: 400;
    line-height: 2rem;
    border-radius: 1rem !important;

    &:focus-within {
      outline: none;
    }
    &--selected {
      background-color: cc(primary, darkest);
      color: white !important;
      font-weight: 600;
      &:hover {
        background-color: cc(primary, darkest) !important;
      }
      &::before {
        @extend .gradiant;
        border-radius: 1rem;
      }
    }
  }
  &__day:not(.react-datepicker__day--disabled) {
    color: cc(primary, darkest);
    &:hover {
      background-color: cc(primary, darkest, 0.2);
      color: cc(primary, darkest);
    }
  }
  &__day--disabled {
    color: cc(offset, default);
  }
  &__day--in-range {
    position: relative;
    font-weight: 800 !important;
    background-color: transparent;
    color: cc(primary, darkest);
    &:hover {
      background-color: cc(primary, darkest);
      color: white;
    }
    &::before {
      @extend .gradiant;
      z-index: -1;
      border-radius: 0;
    }
  }
  &__day--range-start,
  &__day--range-end {
    background-color: cc(primary, darkest) !important;
    border-radius: 1rem;
    color: white !important;
  }
  &__week {
    .react-datepicker__day--in-range:first-child::before,
    .react-datepicker__day--range-start::before {
      @extend .radius-left;
    }
    .react-datepicker__day--in-range:last-child::before,
    .react-datepicker__day--range-end::before {
      @extend .radius-right;
    }
  }
  &__day--in-range-start::before {
    @extend .radius-left;
  }
  &__day--in-range-end::before {
    @extend .radius-right;
  }
  &__navigation {
    top: 1.5em;
    padding: 0.4em;
    &:focus-within {
      outline: none;
    }
    &--previous {
      @extend .arrow;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    &--next {
      @extend .arrow;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
