@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'), url('../static/fonts/ProximaNova-Regular/ProximaNova-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'), url('../static/fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'), url('../static/fonts/ProximaNova-Bold/ProximaNova-Bold.woff2') format('woff2');
}

.drop-pick {
  color: #303030;
  font-size: convert-rem(14);
  font-weight: bold;
  height: convert-rem(17);
  letter-spacing: convert-rem(1.4);
  line-height: convert-rem(17);
}
