.list {
  &__cell {
    &.-tooltip {
      height: fit-content;
      text-overflow: ellipsis;
      margin-top: convert-rem(5);
    }
    &.-align-right.-tooltip {
      text-align: right;
    }
  }

  &__data {
    overflow: hidden;
    flex: auto;

    @media (--tablet) {
      display: flex;
    }
  }

  &__header {
    &.-padded {
      padding: convert-rem(5) 0;
    }
  }

  &__action {
    padding-right: 1rem;
    color: #002144;
    &:hover {
      color: #8e7f58;
    }
  }
}
