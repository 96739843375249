.worker-mobile {
  &.-landscape-rotate {
    @media only screen and (max-width: $breakpoint-mobile) and (orientation: portrait) {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      transform-origin: bottom left;
      position: absolute !important;
      top: -100vw !important;
      height: 100vw;
      width: 100vh;
    }
    @media only screen and (min-width: $breakpoint-mobile) and (orientation: landscape) {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  &.-calendar-landscape-rotate {
    @media only screen and (max-width: $breakpoint-mobile) and (orientation: portrait) {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      transform-origin: bottom right;
      top: convert-rem(360) !important;
    }
    @media only screen and (min-width: $breakpoint-mobile) and (orientation: landscape) {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
