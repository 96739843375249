.popdown {
  position: absolute;
  left: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: fit-content;
  opacity: 0;
  transform: translate3d(0, -0.75rem, 0);
  pointer-events: none;
  z-index: 1;
  transition: transform 250ms cb(), opacity 225ms cb(ease), box-shadow 150ms cb(ease);
  margin: 0;

  .-right-aligned-popdown &,
  .list__cell.-heading:last-child & {
    right: 0;
    left: initial;
  }

  & > * {
    text-align: left;
  }

  &.-open {
    overflow: visible;
    opacity: 1;
    transform: translateZ(0);
    pointer-events: auto;
    z-index: 2;
  }
}
