.select {
  width: inherit;
  .-readonly & {
    pointer-events: none;

    &__indicators {
      display: none;
    }
  }

  .view-only & {
    &__value-container {
      padding: 0;

      &--is-multi {
        display: none;
      }

      &--has-value {
        display: flex;
      }
    }

    &__indicators,
    &__multi-value__remove {
      display: none;
    }

    &__multi-value {
      margin-right: convert-rem(6);
      margin-left: convert-rem(-2);

      &__label {
        padding-right: convert-rem(10);
      }
    }
  }

  .-no-padding &__value-container {
    padding: 0;
  }

  .-group &__input,
  .-group & input {
    width: 100%;
    min-width: convert-rem(350);
  }

  &__multi-value {
    margin: 1px;
    &__label {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.25rem 0px 0.2rem 0.625rem;
    }
    &__remove svg {
      width: 14px;
      height: 14px;
    }
  }

  &__option {
    font-size: 0.8125rem;
    font-weight: 600;
    text-overflow: initial;
    white-space: normal;
    &-text {
      display: initial;
    }
  }

  &__control {
    padding-right: convert-rem(20);

    .-readonly &,
    .view-only & {
      border: none;
    }

    .-no-padding & {
      padding: 0;
    }

    fieldset:disabled & {
      opacity: $disabled-opacity;
      cursor: $cursor-not-allowed;
    }

    .view-only:disabled & {
      opacity: 1;
      cursor: inherit;
    }
  }

  &__option {
    @include type(label);
    text-align: left;
  }

  &__placeholder {
    @include type(placeholder);
    padding-top: 0.125rem;
    color: cc(offset, dark, 0.5);

    .view-only & {
      opacity: 0;
    }
  }

  &__caret {
    color: cc(offset, dark);
    transform: scaleY(1);
    backface-visibility: hidden;
    transition: transform 125ms cb();
    font-size: 0.5rem;

    &.-open {
      transform: scaleY(-1);
    }
  }

  &__clear {
    margin-right: 0.6rem;
    font-size: 0.5rem;
  }

  &__multi-value__remove:hover {
    background-color: cc(offset, neutral);

    & svg {
      color: cc(grey, neutral);
      background-color: cc(offset, neutral);
      border-radius: convert-rem(10);
    }
  }
}
