@import 'variables';

.badge {
  &.-padded {
    margin-left: convert-rem(10);
  }

  &.-heading {
    margin-bottom: convert-rem(15);
  }

  &.-disk {
    width: convert-rem(26);
    height: convert-rem(26);
    border-radius: 100%;
    font-size: convert-rem(14);

    &.-small {
      color: cc(offset, medium);
      font-size: convert-rem(12);
    }
  }

  &.-center {
    margin: auto;
    text-align: center;
  }

  &.-error {
    color: cc(state, error);
  }

  &.-primary {
    color: cc(primary);
  }

  &.-accent {
    color: cc(accent);
  }

  &__icon.-padded {
    transform: scale(0.75);
  }

  &__icon.-no-margin-right {
    margin-right: 0;
  }

  &__traffic {
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 0.5rem 0 0.3rem;
    border-radius: 50%;
    opacity: 0.8;

    &.-drop {
      background-color: $dropColor;
    }

    &.-pick {
      background-color: $pickColor;
    }
  }
}
