.actions {
  position: relative;

  @media (--laptop) {
    margin-left: convert-rem(20);
  }

  &__header {
    display: flex;
    align-items: center;
    margin-left: 0;
  }

  &__list {
    position: absolute;
    top: calc(100% + 0.25rem);
    right: 0;
    min-width: 100%;
  }

  &__option {
    padding: 0.75rem 1rem;
    color: cc(grey);
    background-color: cc(primary, darkest);
    cursor: pointer;
    white-space: nowrap;

    &:first-of-type {
      padding-top: 1.2rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-of-type {
      padding-bottom: 1.2rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      color: cc(offset, lightest);
    }
  }
}
