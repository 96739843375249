$breakpoint-min-width: em(320);
$breakpoint-phone: em(567);
$breakpoint-tablet: em(568);
$breakpoint-mobile: em(1024);
$breakpoint-laptop: em(1025);
$breakpoint-desktop: em(1360);
$breakpoint-max-width: em(1520);

@custom-media --min-width only screen and (max-width: #{em(320)});
@custom-media --phone only screen and (max-width: #{em(567)});
@custom-media --tablet only screen and (min-width: #{em(568)});
@custom-media --mobile only screen and (max-width: #{em(1024)});
@custom-media --laptop only screen and (min-width: #{em(1025)});
@custom-media --desktop only screen and (min-width: #{em(1360)});
@custom-media --max-width only screen and (min-width: #{em(1520)});
