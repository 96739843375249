.filter {
  position: absolute;
  top: 100%;
  min-width: calc(100% + 0.75rem);
  padding: convert-rem(20);
  background-color: white;
  border-radius: 0.25rem;
  z-index: 2;

  &.-open {
    box-shadow: 0 0.25rem 1rem rgba(black, 0.15);
  }

  &.-global {
    right: convert-rem(10);
    left: unset;
    min-width: convert-rem(300);
  }

  &.-medium-width {
    min-width: convert-rem(225);
  }

  &.-tabs-width {
    left: -0.5rem;
    min-width: calc(100% - 1rem);
  }

  &__label {
    @include type(label);
    display: block;
    width: 100%;
    color: cc(grey);
    text-align: start;

    & + & {
      margin-top: convert-rem(20);
    }

    &:hover {
      color: cc(primary);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: convert-rem(20);

    &.-align-right {
      justify-content: flex-end;
    }
  }

  &__title {
    @include type(legend, light);
    margin-right: convert-rem(10);
    color: cc(grey);
    white-space: nowrap;
  }

  &__action {
    margin-left: convert-rem(10);
  }
}
