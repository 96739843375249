.selectbox {
  position: relative;
  display: flex;
  padding-top: convert-rem(5);

  ::-webkit-scrollbar {
    display: none;
  }

  .popdown {
    position: absolute;
    top: calc(100% + #{convert-rem(5)});
  }

  &__caret {
    color: cc(offset, dark);
    transform: scaleY(1);
    backface-visibility: hidden;
    transition: transform 125ms cb();
    font-size: 0.5rem;

    &.-open {
      transform: rotate(180deg);
    }

    .-dark & {
      color: cc(primary);
    }
  }

  &__highlight {
    color: black;
    font-weight: 700;
  }

  &__indicators {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    box-sizing: border-box;
    height: 100%;
  }

  &__list {
    flex-grow: 1;
    min-width: 100%;
    max-height: convert-rem(170);
    padding: convert-rem(5) 0;
    color: cc(offset, dark);
    background-color: white;
    border: 1px solid cc(grey, light);
    border-radius: 4px;

    &.popdown.-open {
      overflow-y: scroll;
    }

    .-dark & {
      padding: 0;
    }
  }

  &__option {
    padding: convert-rem(5) convert-rem(10);
    cursor: pointer;
    &:hover {
      background-color: cc(grey, light);
    }

    .-dark & {
      @extend .actions__option;

      &:hover {
        background-color: cc(primary, darkest);
      }
    }
  }

  &__value {
    position: relative;
    flex-grow: 1;
    height: convert-rem(22);
    padding-right: convert-rem(20);
    background-color: transparent;
    border-bottom: 1px solid rgb(222, 222, 222);
    transition: border-color 125ms linear;
    text-align: left;
    white-space: nowrap;

    &.-placeholder {
      @include type(placeholder);
      color: cc(offset, dark);
    }

    &.-readonly {
      border-bottom: none;
      cursor: default;
    }

    &:hover {
      border-color: cc(grey, lighter);
    }

    .-dark & {
      @include type(link);
      height: auto;
      color: cc(primary);
      border: none;
    }

    &.-disabled,
    &:disabled {
      opacity: $disabled-opacity;
      cursor: $cursor-not-allowed;
    }

    &:focus,
    &:active {
      border-color: cc(primary);
    }
  }
}
